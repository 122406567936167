import React, { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import "../css/Posts.css"
import SEO from "../components/Seo"
import Img from "gatsby-image"

class Post extends Component {
  render() {
    const post = this.props.data.wordpressPost
    // const postid = this.props.data.wordpressPost.wordpress_id
    const yoast = this.props.data.wordpressPost.yoast_head
    const post_title = post.title
    const resolutions =
      post.featured_media.localFile.childImageSharp.fluid.originalImg

    return (
      <>
        <Layout>
          <SEO title={{ yoast }} />
          <section className="posts" id="posts">
            <div className="container" id="single-posts">
              <div className="row">
                <div className="col-md-12">
                  <img
                    src={resolutions}
                    alt={post_title}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10">
                  <h1
                    dangerouslySetInnerHTML={{ __html: post.title }}
                    className="h1-title"
                  ></h1>
                  <div dangerouslySetInnerHTML={{ __html: post.content }} />
                </div>
                <div className="col-md-1"></div>
              </div>
            </div>
          </section>
        </Layout>
      </>
    )
  }
}

Post.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default Post

export const postQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      wordpress_id
      yoast_head
      featured_media {
        localFile {
          childImageSharp {
            fluid {
              originalImg
            }
          }
        }
      }
      title
      content
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
